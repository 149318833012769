import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { BankAccountStore } from '../../store/bankAccountStore';
import { BankScrapingManagerStore } from '../../store/bankScrapingManager';
import { Deposits } from './deposits/deposits.component';
import { Withdrawals } from './withdrawals/withdrawals.component';
import { PendingTransactions } from './pending-transactions/pending-transactions.component';
import { Config } from './config/config.component';
import { DropDownButton } from '../../components/drop-down-button/drop-down-button.component';
import { DropDownOption } from '../../components/drop-down-option/drop-down-option.component';
import { RegisterDepositModal } from './register-deposit-modal/register-deposit-modal.component';
import { RegisterTransferModal } from './register-transfer-modal/register-transfer-modal.component';
import { RegisterWithdrawalModal } from './register-withdrawal-modal/register-withdrawal-modal.component';
import { RegisterProfitWithdrawalModal } from './register-profit-withdrawal-modal/register-profit-withdrawal-modal.component';
import { RegisterBalancePurchaseModal } from './register-balance-purchase-modal/register-balance-purchase-modal.component';
import { style } from './bank-account.module.css';
import { OutlineCard, ToolbarTitle, Text, PageContainer, PageToolbar, PageContent, TabBar, Tab, Switch, Case, DetailItem, } from '@recargas-dominicanas/core/components';
export function BankAccount() {
    var params = useParams();
    var bankAccountId = parseInt(params.id);
    var bankAccount = BankAccountStore.getById(bankAccountId);
    var _a = useState('deposits'), selectedTab = _a[0], setSelectedTab = _a[1];
    var _b = useState(false), openRegisterDeposit = _b[0], setOpenRegisterDeposit = _b[1];
    var _c = useState(false), openRegisterTransferModal = _c[0], setOpenRegisterTransferModal = _c[1];
    var _d = useState(false), openRegisterBalancePurchaseModal = _d[0], setOpenRegisterBalancePurchaseModal = _d[1];
    var _e = useState(false), openRegisterWithdrawalModal = _e[0], setOpenRegisterWithdrawalModal = _e[1];
    var _f = useState(false), openRegisterProfitWithdrawalModal = _f[0], setOpenRegisterProfitWithdrawalModal = _f[1];
    useEffect(function () {
        BankAccountStore.fetchById(bankAccountId);
        BankAccountStore.fetchDeposits(bankAccountId, 1, 100);
        BankAccountStore.fetchWithdrawals(bankAccountId, 1, 100);
        BankAccountStore.fetchConfigs(bankAccountId);
        BankAccountStore.fetchPendingTransactions(bankAccountId, 1, 25);
        BankAccountStore.fetchLastBankScrapingManager(bankAccountId);
        BankScrapingManagerStore.fetchNextExecution();
    }, [bankAccountId]);
    return !bankAccount ? null : (React.createElement(PageContainer, { className: style.container },
        React.createElement(PageToolbar, { className: style.toolbar },
            React.createElement(ToolbarTitle, { style: style.title, title: 'Cuenta bancaria' }),
            React.createElement("div", { className: style.toolbarRight },
                React.createElement(TabBar, { onTabClick: function (tab) { return setSelectedTab(tab.tag); } },
                    React.createElement(Tab, { style: style.tab, tag: 'deposits', title: 'Depositos' }),
                    React.createElement(Tab, { style: style.tab, tag: 'withdrawals', title: 'Retiros' }),
                    React.createElement(Tab, { style: style.tab, tag: 'pending-transactions', title: 'Transacciones' }),
                    React.createElement(Tab, { style: style.tab, tag: 'config', title: 'Configuraci\u00F3n' })),
                React.createElement(DropDownButton, { icon: 'payments', title: 'Registrar deposito', onClick: function () { return setOpenRegisterDeposit(true); } },
                    React.createElement(DropDownOption, { title: 'Registrar transferencia', onClick: function () { return setOpenRegisterTransferModal(true); } }),
                    React.createElement(DropDownOption, { title: 'Registrar compra de balance', onClick: function () { return setOpenRegisterBalancePurchaseModal(true); } }),
                    React.createElement(DropDownOption, { title: 'Registrar retiro', onClick: function () { return setOpenRegisterWithdrawalModal(true); } }),
                    React.createElement(DropDownOption, { title: 'Registrar retiro de beneficio', onClick: function () { return setOpenRegisterProfitWithdrawalModal(true); } })))),
        React.createElement(PageContent, { className: style.content },
            React.createElement(OutlineCard, { className: style.outlineCard },
                React.createElement("img", { className: style.image, src: "".concat(process.env.API, "/image/").concat(bankAccount.bank.image) }),
                React.createElement(Text, { className: style.bankAccountTitle, text: bankAccount.name }),
                React.createElement(DetailItem, { style: style.detailItem, title: 'Cuenta', text: bankAccount.accountNumber }),
                React.createElement(DetailItem, { style: style.detailItem, title: 'Balance', color: 'green', text: formatCurrency(bankAccount.balance) })),
            React.createElement(OutlineCard, { className: style.outlineCardRight },
                React.createElement(Switch, { caseId: selectedTab },
                    React.createElement(Case, { caseId: 'deposits' },
                        React.createElement(Deposits, { id: bankAccountId })),
                    React.createElement(Case, { caseId: 'withdrawals' },
                        React.createElement(Withdrawals, { id: bankAccountId })),
                    React.createElement(Case, { caseId: 'pending-transactions' },
                        React.createElement(PendingTransactions, { id: bankAccountId })),
                    React.createElement(Case, { caseId: 'config' },
                        React.createElement(Config, { id: bankAccountId }))))),
        React.createElement(RegisterDepositModal, { open: openRegisterDeposit, bankAccountId: bankAccountId, onClose: function () { return setOpenRegisterDeposit(false); } }),
        React.createElement(RegisterTransferModal, { open: openRegisterTransferModal, bankAccountId: bankAccountId, balance: bankAccount.balance, onClose: function () { return setOpenRegisterTransferModal(false); } }),
        React.createElement(RegisterBalancePurchaseModal, { open: openRegisterBalancePurchaseModal, bankAccountId: bankAccountId, balance: bankAccount.balance, onClose: function () { return setOpenRegisterBalancePurchaseModal(false); } }),
        React.createElement(RegisterWithdrawalModal, { open: openRegisterWithdrawalModal, bankAccountId: bankAccountId, balance: bankAccount.balance, onClose: function () { return setOpenRegisterWithdrawalModal(false); } }),
        React.createElement(RegisterProfitWithdrawalModal, { open: openRegisterProfitWithdrawalModal, bankAccountId: bankAccountId, balance: bankAccount.balance, onClose: function () { return setOpenRegisterProfitWithdrawalModal(false); } })));
}
